<template>
    <div>
        <div class="col-lg-12 mt-10">
            <h3 class="font-weight-bold mb-3 text-dark">So how can I help?</h3>
            <div class="font-weight-nromal font-size-lg mb-6">
                <p>
                    Enter text below and see what Maura thinks of it. This is a great place for you to paste any text you
                    may be posting in an upcoming session.
                    Once entered, click the Test button and Maura will score your message. If the 0% toxic or 0% spam result
                    shows green, your message will pass,
                    if it shows red, consider rewriting it. Red messages will be marked as a message that does not meet our
                    community guidelines. Your privacy
                    and security mean everything to us. Anything you enter below is completely anonymous. Project Pinskey will
                    collect that data and periodically
                    retrain itself to become smarter. A win-win for everyone.
                </p>
            </div>
        </div>
        <!--begin::Todo-->
        <div class="col-lg-12 mt-6">
            <div style="margin: 0 auto;">
                <div class="mb-3">
                    <div class="form-group">
                        <textarea class="form-control form-control-solid form-control-lg" id="toxicText" ref="toxicText"
                            name="toxicText" v-model="form.toxicText" rows="3"></textarea>
                        <span class="form-text text-dark">Version 5.24.1</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex align-items-sm-center flex-wrap flex-column flex-sm-row">
            <div class="d-flex">
                <div class="d-flex ml-4 mr-7">
                    <span class="svg-icon svg-icon-gray-500">
                        <!--begin::Svg Icon | path:assets/media/svg/icons/Text/Bullet-list.svg-->
                        <i class="flaticon2-cross icon-sm" :class="toxicScore > 90 ? 'text-red' : 'text-green'"></i>
                        <!--end::Svg Icon-->
                    </span>
                    <a href="#" class="font-weight-boldest text-primary ml-2"
                        :class="toxicScore > 90 ? 'text-red' : 'text-green'">{{ toxicScore }}%
                        Toxic</a>
                </div>
                <div class="d-flex mr-7">
                    <span class="svg-icon svg-icon-gray-500">
                        <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Group-chat.svg-->
                        <i class="flaticon2-shopping-cart icon-sm" :class="spamScore > 90 ? 'text-red' : 'text-green'"></i>
                        <!--end::Svg Icon-->
                    </span>
                    <a href="#" class="font-weight-boldest text-primary ml-2"
                        :class="spamScore > 90 ? 'text-red' : 'text-green'">{{ spamScore }}% Spam</a>
                </div>
            </div>
            <button type="reset" @click="clear()"
                class="btn btn-clean font-weight-bold  mt-5 mt-sm-0 mr-2 ml-sm-auto">Clear</button>
            <b-button class="btn btn-primary btn-sm font-weight-bolder mr-10" variant="primary" disabled v-show="loading">
                <b-spinner small type="grow"></b-spinner>
                &nbsp;Predicting...
            </b-button>
            <button type="button" @click="predictText()" class="btn btn-primary btn-sm font-weight-bolder mr-10"
                v-show="!loading">
                Test
            </button>
        </div>
        <div class="col-xl-12 hr-grey mt-10">
            <!--begin::Form-->
            <form class="form" novalidate="novalidate" id="kt_save_changes_form">
                <div class="mt-10">
                    <h3 class="font-weight-bold mb-3 text-dark">Its your turn!</h3>
                    <div class="font-weight-nromal font-size-lg mb-6">
                        <p>
                            So what did you find? Submit the phrase or sentences that made it through in your session, or
                            test above, and tell us if it should
                            be considered toxic or spam. Project Pinskey will take it from there.
                        </p>
                    </div>
                    <div class="row">
                        <!-- <div class="col-xl-2"></div> -->
                        <div class="col-xl-12">
                            <!--begin::Input-->
                            <div class="form-group">
                                <label for="exampleTextarea">Your suggestion</label>
                                <textarea class="form-control form-control-solid form-control-lg toxicspamtext"
                                    name="toxicspamtext" id="toxicspamtext" ref="toxicspamtext" rows="3"
                                    v-model="toxicForm.toxicText"></textarea>
                                <span class="form-text text-dark">All submissions are anonymous.</span>
                            </div>
                            <!--end::Input-->
                            <div class="form-group">
                                <label for="exampleTextarea">What type of text is it?</label>
                                <b-form-select v-model="toxicForm.toxicType" :options="options"></b-form-select>
                            </div>
                        </div>
                        <!-- <div class="col-xl-2"></div> -->
                    </div>
                    <div class="row">
                        <!-- <div class="col-xl-2"></div> -->
                        <div class="col-xl-12">
                            <button ref="kt_save_changes" class="btn btn-primary font-weight-bold mr-2">Submit</button>
                            <!-- <button type="reset" class="btn btn-clean font-weight-bold">Cancel</button> -->
                        </div>
                        <!-- <div class="col-xl-2"></div> -->
                    </div>
                </div>
            </form>
            <!--end::Form-->

        </div>


    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import KTUtil from "@/assets/js/components/util";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

export default {
    name: 'textclassification',
    data() {
        return {
            form: {
                toxicText: ""
            },
            toxicForm: {
                toxicText: "",
                toxicType: "toxic"
            },
            loading: false,
            selected: 'toxic',
            options: [
                { value: 'toxic', text: 'The text is toxic or obscene in nature.' },
                { value: 'spam', text: 'The text contains spam or advertising.' }
            ]
        }
    },
    mounted() {
        this.clear()
        const changes_form = KTUtil.getById("kt_save_changes_form");

        this.fv = formValidation(changes_form, {
            fields: {
                toxicspamtext: {
                    validators: {
                        notEmpty: {
                            message: "Suggestion text is required",
                        },
                    },
                },
            },
            plugins: {
                trigger: new Trigger(),
                submitButton: new SubmitButton(),
                bootstrap: new Bootstrap(),
            },
        });

        let self = this;
        this.fv.on("core.form.valid", () => {
            // set spinner to submit button
            const submitButton = this.$refs["kt_save_changes"];
            submitButton.classList.add("spinner", "spinner-light", "spinner-right");

            this.$store.dispatch("submitToxicText", this.toxicForm)
                .then(
                    function (value) {
                        Swal.fire({
                            title: "",
                            text: "Thank you for your submission.",
                            icon: "success",
                            confirmButtonClass: "btn btn-secondary",
                            heightAuto: true,
                        }).then((result) => {
                            submitButton.classList.remove(
                                "spinner",
                                "spinner-light",
                                "spinner-right"
                            );
                            self.toxicForm.toxicText = ""
                            self.toxicForm.toxicType = "toxic"
                        })
                    },
                    function (error) {
                        submitButton.classList.remove(
                            "spinner",
                            "spinner-light",
                            "spinner-right"
                        );
                        Swal.fire({
                            title: "",
                            text: error,
                            icon: "error",
                            confirmButtonClass: "btn btn-secondary",
                            heightAuto: true,
                        });
                    });
        });

        this.fv.on("core.form.invalid", () => {
            Swal.fire({
                title: "",
                text: "Please, provide correct data!",
                icon: "error",
                confirmButtonClass: "btn btn-secondary",
                heightAuto: true,
            });
        });

    },
    methods: {
        async predictText() {
            this.$store.dispatch("resetMLPredict")
            if (this.form.toxicText.length > 0) {
                this.loading = true
                this.$store.dispatch("calculateToxicScore", {
                    toxicText: this.form.toxicText,
                });
                this.$store.dispatch("calculateSpamScore", {
                    spamText: this.form.toxicText,
                });
            }
        },
        clear() {
            this.$store.dispatch("resetMLPredict")
            this.form.toxicText = ""
            this.loading = false
        }
    },
    computed: {
        ...mapGetters(["toxicScore", "spamScore"]),
    },
    watch: {
        toxicScore(newValue) {
            if (newValue > 0) {
                this.loading = false
            }
        },
        spamScore(newValue) {
            if (newValue > 0) {
                this.loading = false
            }
        }
    }
}
</script>

<style scoped>
.text-green {
    color: green !important;
}

.text-red {
    color: red !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.hr-grey {
    border-top: 1px solid #EBEDF3;
}
</style>